import React from 'react'

import 'twin.macro'
import tw, { TwStyle } from 'twin.macro'

import { Carousel } from 'mx/components/new/'
import { ContentModule, getParagraph } from 'mx/components/new/utils'

interface ContentCarouselProps {
  classname?: TwStyle
  data: ContentModule
  projects?: boolean
}

export const ContentCarousel = ({
  classname,
  data,
  projects,
}: ContentCarouselProps) => {
  const { contentSlots } = data
  if (!contentSlots) return null
  const { markdown, slotTitle, photos } = contentSlots[0]

  return (
    <div
      tw="w-full flex flex-col mx-auto space-y-10 text-gray-dark font-light px-6 md:px-8 md:flex-row md:justify-between md:max-w-6xl"
      css={[classname]}
    >
      <div tw="w-full space-y-12">
        <div tw="w-full">
          <Carousel images={photos} />
        </div>
        {!projects && (
          <div tw="space-y-8">
            {!!slotTitle && (
              <h3 tw="text-2xl md:text-3xl text-balance">{slotTitle}</h3>
            )}
            {getParagraph(markdown, tw`text-base md:text-lg text-balance`)}
          </div>
        )}
      </div>
    </div>
  )
}
